@import "./index.scss";

.list-1 {
  h1 {
    margin-top: 60px;
  }
  p {
    padding: 20px 40px;
    text-align: center;
    font-size: 18px;
    margin: 0 auto;
    max-width: 680px;
  }
  .card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0 60px 0;
    padding: 0 20px;
    width: 100%;
    li {
      background: linear-gradient(0deg, $l-blue, $l-green);
      padding: 20px;
      text-align: left;
      width: 100%;
      max-width: 680px;
      margin: 0 auto;
      .expand {
        border-top: 1px solid #fff;
        margin-top: 20px;
        color: #fff;
        h3 {
          font-style: italic;
          margin-top: 20px;
        }
        p {
          line-height: 140%;
          padding: 0;
          text-align: left;
          margin-top: 10px;
        }
      }
    }
    .title {
      align-items: center;
      display: flex;
      gap: 20px;
      .icon {
        max-height: 60px;
      }
      h2 {
        color: #fff;
      }
    }
  }
}

.list-2 {
  h1 {
    margin-top: 20px;
  }
  p {
    padding: 20px 40px;
    text-align: center;
    font-size: 18px;
    margin: 0 auto;
    max-width: 680px;
  }
  .card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0 60px 0;
    padding: 0 20px;
    width: 100%;
    li {
      background: linear-gradient(0deg, $main-color, $yellow);
      padding: 20px;
      text-align: left;
      width: 100%;
      max-width: 680px;
      margin: 0 auto;
      .expand {
        border-top: 1px solid #fff;
        margin-top: 20px;
        color: #fff;
        .to-do-list {
          padding-left: 20px;
          list-style-type: disc;
          li {
            margin-top: 15px;
            background: none;
            padding: 0;
          }
        }
      }
    }
    .title {
      align-items: center;
      display: flex;
      gap: 20px;
      .icon {
        max-height: 60px;
      }
      h2 {
        color: #fff;
      }
    }
  }
}
