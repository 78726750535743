// Montserrat import
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
$main-font: "Raleway", sans-serif;

// Colors
$main-color: #e50046;
$grey: #3c3c3b;
$yellow: #fed82a;
$blue: #325aa6;
$l-blue: #33abdd;
$l-green: #a2d05e;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $main-font;
  ul {
    list-style: none;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
}

::selection {
  background: $main-color;
  color: #fff;
}

h1 {
  color: $grey;
  font-size: 2.4em;
  font-style: italic;
  font-weight: 900;
  text-align: center;
}

$screen-md-min: 54.8rem;
$screen-lg-min: 80rem;

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

.container {
  margin: 0 auto;
  max-width: 80rem;
  width: 100%;
  overflow: hidden;
}
