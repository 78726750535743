@import "./index.scss";

.stickers {
  background-color: $main-color;
  padding: 40px 20px;
  .container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include md {
      flex-direction: row;
      gap: 40px;
    }
  }
  img {
    width: 100%;
    max-width: 420px;
    height: auto;
  }
}

.stickers-text {
  text-align: center;
  font-size: 1.2em;
  font-weight: 900;
  font-style: italic;
  color: $yellow;
  max-width: 360px;
  width: 100%;
  a,
  a:visited {
    border: 2px solid #fff;
    display: table;
    margin: 0 auto;
    margin-top: 20px;
    color: #fff;
    background-color: $main-color;
    border-radius: 20px;
    font-size: 1.2em;
    padding: 15px 30px;
    transition: color ease 500ms, background-color ease 500ms;
  }
  a:hover,
  a:active {
    color: $main-color;
    background-color: #fff;
  }
}
