@import "./index.scss";

.main-header {
  background-image: url("../img/header-bg.jpg");
  background-size: cover;
  display: grid;
  align-content: center;
  place-items: center;
  width: 100%;
  .navigation {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    position: absolute;
    top: 0;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    z-index: 2;
    @include md {
      flex-direction: row;
    }
    .logotype {
      background-color: #fff;
      border-radius: 0 0 20px 20px;
      padding: 20px;
      img {
        max-width: 315px;
        width: 100%;
      }
    }
    ul {
      display: flex;
      li {
        display: grid;
        background-color: $main-color;
        border-radius: 50%;
        margin-left: 6px;
        place-items: center;
        height: 38px;
        width: 38px;
        transition: background-color ease 200ms;
        a,
        a:visited {
          padding: 10px;
          color: #fff;
        }
      }
      li:hover {
        background-color: #d40040;
      }
    }
  }
  .main-hero {
    position: relative;
    z-index: 1;
    img {
      margin-top: 180px;
      width: 100%;
      @include md {
        margin-top: 120px;
      }
    }
  }
  .main-buttons {
    background-color: #ededed;
    border-image: linear-gradient(to right, $main-color 0%, $blue 100%) 1;
    border-width: 0;
    border-style: solid;
    padding: 60px 20px 40px 20px;
    margin-top: -30px;
    width: 100%;
    @include md {
      padding: 80px 20px 60px 20px;
      margin-top: -70px;
      border-width: 40px 0 0 0;
    }
    .container {
      margin: 0 auto;
      img {
        width: 100%;
        max-width: 520px;
        transition: scale ease 400ms;
      }
      img:hover {
        scale: 1.03;
      }
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      @include md {
        gap: 20px;
        flex-direction: row;
      }
    }
  }
}
