@import "./index.scss";

.gallery {
  iframe {
    overflow: hidden;
  }
  .container {
    padding: 40px 20px;
  }
}
