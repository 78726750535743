@import "./index.scss";

.main-footer {
  background-color: $main-color;
  padding: 40px 20px;
  .container {
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include md {
      flex-direction: row;
    }
    .logo-w {
      width: auto;
      height: 50px;
    }
  }
  .button {
    img {
      width: 100%;
      max-width: 400px;
    }
  }
}
